<template>
	<v-container fluid class="px-1 py-0">
		<v-card class=" border-radius-xl mt-3 pb-2">

			<v-tabs-items v-model="currentTab">
				
				<v-tab-item
					:key="0"
				>
					<UserManage></UserManage>
				</v-tab-item>
				
				<v-tab-item
					:key="1"
				>
					<FactorManage></FactorManage>
				</v-tab-item>
				
			</v-tabs-items>
			
		</v-card>
		
		
	</v-container>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import File from "@/components/FileStore/File";
import Recycle from "@/components/FileStore/Recycle";

export default {
	name: 'Manage',
	components: {File,Recycle,},
	data() {
		return {
			currentTab:0,
			
		}
	},
	computed:{
		//动态引入组件
		
	},
	mounted() {
		Bus.$on('fileStoreTabChange', e => {
			this.currentTab=e;
		});
	},
	methods: {
		
	},
};
</script>
